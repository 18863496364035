import React from 'react'
import { Skeleton, Stack } from '@mui/material'

const SpcSkeleton: React.FC = () => {
  return (
    <Stack mx={1} spacing={2} height='100%'>
      <Skeleton variant='rounded' width='35%' height='2rem' />
      <Skeleton variant='rounded' width='100%' height='3rem' />
      <Skeleton variant='rounded' width='45%' height='2rem' />
      <Skeleton variant='rounded' width='100%' height='10rem' />
      <Skeleton variant='rounded' width='40%' height='2rem' />
      <Skeleton variant='rounded' width='100%' height='20rem' />
    </Stack>
  )
}

export default SpcSkeleton
