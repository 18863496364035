import React, { useEffect, useState } from 'react'

import { CalculationProps, RequiredParamsProps, OptionalParamsProps } from '../../types/MedicalCalculations'
import CalculationCard from './CalculationCard'
import { useAuth } from '../../context/AuthContext'

const CHA2DS2VASc: React.FC<CalculationProps> = (props) => {
  const [data, setData] = useState<string | undefined>(undefined)
  const [requiredParams, setRequiredParams] = useState<RequiredParamsProps>({})
  const [optionalParams, setOptionalParams] = useState<OptionalParamsProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { backendRequest } = useAuth()

  useEffect(() => {
    setIsLoading(true)
    const reqParamsObj = {
      age: props.formData.age,
      gender: props.formData.gender,
      hypertension: props.formData.hypertension,
      chronic_heart_failure_history: props.formData.chronic_heart_failure_history,
      vascular_disease_history: props.formData.vascular_disease_history,
      diabetes_history: props.formData.diabetes_history,
    }
    setRequiredParams(reqParamsObj)

    const optParams = [
      {
        labels: ['Stroke history', 'Thromboembolism history', 'TIA history'],
        params: ['stroke_history', 'thromboembolism_history', 'tia_history'],
        value: props.formData.stroke_history !== '' || props.formData.tia_history !== '' || props.formData.thromboembolism_history !== '' ? true : false,
      },
    ]
    setOptionalParams(optParams)

    const reqParamsPresent = Object.values(reqParamsObj).every((value) => {
      if (value === null || value === undefined || value === '') {
        return false
      }
      return true
    })

    const optParamsPresent = optParams.every((obj) => {
      if (obj.value === true) {
        return true
      }
      return false
    })

    const getData = async () => {
      if (reqParamsPresent && optParamsPresent) {
        const response = await backendRequest({
          method: 'GET',
          endpoint: '/api/medical-calculations/cha2ds2-vasc',
          requiresAuth: true,
          params: {
            age: props.formData.age,
            gender: props.formData.gender,
            hypertension: props.formData.hypertension,
            chronic_heart_failure_history: props.formData.chronic_heart_failure_history,
            vascular_disease_history: props.formData.vascular_disease_history,
            diabetes_history: props.formData.diabetes_history,
            stroke_history: props.formData.stroke_history !== '' ? props.formData.stroke_history : null,
            tia_history: props.formData.tia_history !== '' ? props.formData.tia_history : null,
            thromboembolism_history: props.formData.thromboembolism_history !== '' ? props.formData.thromboembolism_history : null,
          },
        })
        return response
      } else {
        return undefined
      }
    }
    getData().then((response) => {
      if (response && response.status === 200) {
        setData(response.data)
      } else {
        setData(undefined)
      }
      setIsLoading(false)
    })
  }, [props.formData])

  return (
    <CalculationCard
      title='CHA2DS2–VASc'
      value={data}
      units='points'
      requiredParams={requiredParams}
      optionalParams={optionalParams}
      isLoading={isLoading}
      formData={props.formData}
      setFormData={props.setFormData}
    />
  )
}

export default CHA2DS2VASc
