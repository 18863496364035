import * as React from 'react'
import { Grid } from '@mui/material/'

import Score2Op from './Score2Op'
import MetabolicSyndrome from './MetabolicSyndrome'
import CkdNomenclature from './CkdNomenclature'
import CHA2DS2VASc from './CHA2DS2VASc'
import GFRByCreatinineS from './GFRByCreatinineS'
import GFRByCystatinCS from './GFRByCystatinCS'
import Bmi from './Bmi'
import SodiumCorrectionForHyperglycemia from './SodiumCorrectionForHyperglycemia'
import ChildPughScore from './ChildPughScore'
import HASBled from './HASBled'
import { CalculationProps } from '../../types/MedicalCalculations'

type Props = CalculationProps & {
  setChildPughScore: (childPughScore: string) => void
  setGfr: (gfr: string) => void
}

const MedicalCalculations: React.FC<Props> = (props) => {
  return (
    <Grid container spacing={3} justifyContent='center'>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <Score2Op formData={props.formData} setFormData={props.setFormData} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <MetabolicSyndrome formData={props.formData} setFormData={props.setFormData} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <CkdNomenclature formData={props.formData} setFormData={props.setFormData} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <GFRByCreatinineS formData={props.formData} setFormData={props.setFormData} setGfr={props.setGfr} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <GFRByCystatinCS formData={props.formData} setFormData={props.setFormData} setGfr={props.setGfr} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <SodiumCorrectionForHyperglycemia formData={props.formData} setFormData={props.setFormData} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <CHA2DS2VASc formData={props.formData} setFormData={props.setFormData} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <HASBled formData={props.formData} setFormData={props.setFormData} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <ChildPughScore formData={props.formData} setFormData={props.setFormData} setChildPughScore={props.setChildPughScore} />
      </Grid>
      <Grid item xs={11} sm={6} md={6} lg={5} xl={4}>
        <Bmi formData={props.formData} setFormData={props.setFormData} />
      </Grid>
    </Grid>
  )
}

export default MedicalCalculations
