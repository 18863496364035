import React, { useEffect, useState } from 'react'

import { CalculationProps, RequiredParamsProps } from '../../types/MedicalCalculations'
import CalculationCard from './CalculationCard'
import { useAuth } from '../../context/AuthContext'

const HASBled: React.FC<CalculationProps> = (props) => {
  const [data, setData] = useState<string | undefined>(undefined)
  const [requiredParams, setRequiredParams] = useState<RequiredParamsProps>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { backendRequest } = useAuth()

  useEffect(() => {
    setIsLoading(true)

    const reqParamsObj = {
      age: props.formData.age,
      gender: props.formData.gender,
      alcohol_use: props.formData.alcohol_use,
      creatinine_s: props.formData.creatinine_s,
      bilirubin_s: props.formData.bilirubin_s,
      ast: props.formData.ast,
      alt: props.formData.alt,
      alp: props.formData.alp,
      systolic_pressure: props.formData.systolic_pressure,
      stroke_history: props.formData.stroke_history,
      chronic_liver_disease_cirrhosis: props.formData.chronic_liver_disease_cirrhosis,
      labile_inr: props.formData.labile_inr,
      medication_predisposing_to_bleeding: props.formData.medication_predisposing_to_bleeding,
      prior_major_bleeding_or_predisposition_to_bleeding: props.formData.prior_major_bleeding_or_predisposition_to_bleeding,
    }
    setRequiredParams(reqParamsObj)

    const allParamsPresent = Object.values(reqParamsObj).every((value) => {
      if (value === null || value === undefined || value === '') {
        return false
      }
      return true
    })

    const getData = async () => {
      if (allParamsPresent) {
        const response = await backendRequest({
          method: 'GET',
          endpoint: '/api/medical-calculations/has-bled',
          requiresAuth: true,
          params: {
            age: props.formData.age,
            creatinine_s: props.formData.creatinine_s,
            gender: props.formData.gender,
            alcohol_use: props.formData.alcohol_use,
            bilirubin_s: props.formData.bilirubin_s,
            ast: props.formData.ast,
            alt: props.formData.alt,
            alp: props.formData.alp,
            systolic_pressure: props.formData.systolic_pressure,
            stroke_history: props.formData.stroke_history,
            chronic_liver_disease_cirrhosis: props.formData.chronic_liver_disease_cirrhosis,
            labile_inr: props.formData.labile_inr,
            medication_predisposing_to_bleeding: props.formData.medication_predisposing_to_bleeding,
            prior_major_bleeding_or_predisposition_to_bleeding: props.formData.prior_major_bleeding_or_predisposition_to_bleeding,
          },
        })
        return response
      } else {
        return undefined
      }
    }
    getData().then((response) => {
      if (response && response.status === 200) {
        setData(response.data)
      } else {
        setData(undefined)
      }
      setIsLoading(false)
    })
  }, [props.formData])

  return (
    <CalculationCard
      title='HAS-BLED'
      value={data}
      units='points'
      requiredParams={requiredParams}
      isLoading={isLoading}
      formData={props.formData}
      setFormData={props.setFormData}
    />
  )
}

export default HASBled
