import React, { useMemo } from 'react'

import { Grid, Paper, Typography, Tooltip, Box, Card, CardHeader, CardContent, List, ListItem, ListItemText, Chip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { GridColumns, DataGridProps } from '@mui/x-data-grid'

import { Table } from '../Table'
import { DosageAdjustment } from '../../types/DosageAdjustment'
import { PatientFormData } from '../Constants'
import SpcDialog from '../dialogs/SpcDialog'
import { ActiveSubstanceSpcCell } from '../ActiveSubstanceCell'
import { iconsObj } from '../../icons/Icons'
import capitalizeFirstLetter from '../scripts/CapitalizeFirstLetter'

type Props = {
  formData: PatientFormData
  dosageAdjustmentData: DosageAdjustment[]
  childPughScore: string
  gfr: string
}

type ReqPatientDataProps = {
  id: string
  name: string
  units: string
  value: string
  description: string
}

const DosageAdjusment: React.FC<Props> = (props) => {
  const [spcOpen, spcSetOpen] = React.useState<boolean>(false)
  const [activeSubstance, setActiveSubstance] = React.useState<string>('')

  const columns: GridColumns = useMemo(
    (): DataGridProps['columns'] => [
      {
        field: 'active_substance',
        headerName: 'Active substance',
        editable: false,
        headerAlign: 'left',
        flex: 1,
        renderCell: (params) => (
          <ActiveSubstanceSpcCell
            activeSubstance={params.row.interaction_checker}
            setActiveSubstance={setActiveSubstance}
            hasSpcs={params.row.has_spcs}
            spcSetOpen={spcSetOpen}
          />
        ),
      },
      {
        field: 'impairments',
        headerName: 'Reason',
        editable: false,
        headerAlign: 'left',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <React.Fragment>
            {params.row.impairments.map((impairment: string) => (
              <Chip size='small' key={impairment} label={capitalizeFirstLetter(impairment)} sx={{ mr: 1 }} />
            ))}
          </React.Fragment>
        ),
      },
    ],
    []
  )

  const childPughScore: string = props.formData.child_pugh_score !== '' ? props.formData.child_pugh_score : props.childPughScore
  const gfr: string = props.formData.gfr !== '' ? props.formData.gfr : props.gfr

  const reqPatientData: ReqPatientDataProps[] = useMemo(
    () => [
      { id: 'creatinine_s', name: 'Creatinine - S', units: 'mmol/l', value: props.formData.creatinine_s, description: '' },
      { id: 'bilirubin_s', name: 'Bilirubin - S', units: 'umol/l', value: props.formData.bilirubin_s, description: '' },
      { id: 'ast', name: 'AST', units: 'ukat/l', value: props.formData.ast, description: '' },
      { id: 'alt', name: 'ALT', units: 'ukat/l', value: props.formData.alt, description: '' },
      { id: 'gmt', name: 'GMT', units: 'ukat/l', value: props.formData.gmt, description: '' },
      { id: 'alp', name: 'ALP', units: 'ukat/l', value: props.formData.alp, description: '' },
      { id: 'age', name: 'Age', units: 'yrs', value: props.formData.age, description: '' },
      { id: 'weight', name: 'Weight', units: 'kg', value: props.formData.weight, description: '' },
      {
        id: 'gfr',
        name: 'GFR',
        units: 'ml/s/1.73m^2',
        value: gfr,
        description: 'The GFR entered by the user has higher priority over the GFR resulting from Medical calculations.',
      },
      {
        id: 'child_pugh_score',
        name: 'Child-Pugh SCORE',
        units: '',
        value: childPughScore,
        description: 'The Child-Pugh SCORE entered by the user has higher priority over the Child-Pugh SCORE resulting from Medical calculations.',
      },
    ],
    [props.formData, props.childPughScore, props.gfr]
  )

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent='center' sx={{ minHeight: 'calc(65vh)' }}>
        <Grid item xs={11} sm={11} md={5} lg={4} xl={4} sx={{ minHeight: '100%', flexdirection: 'column' }}>
          <Paper sx={{ width: '100%', boxShadow: 6, minHeight: '40rem', height: '100%' }}>
            <Card sx={{ boxShadow: 6, height: '100%' }}>
              <CardHeader sx={{ pb: 0 }} titleTypographyProps={{ variant: 'subtitle1', fontWeight: 700 }} title={'Used patient’s data and calculations'} />
              <CardContent>
                <List sx={{ m: 0, p: 0 }}>
                  {reqPatientData.map((data, i) => (
                    <ListItem key={i} sx={{ m: 0, p: 0 }}>
                      <ListItemText primary={data.name} secondary={`${data.value !== '' ? data.value : '--'} ${data.units}`} />
                      {data.description && (
                        <Tooltip placement='right' title={data.description}>
                          <InfoOutlinedIcon fontSize='small' color='disabled' sx={{ mr: 1 }} />
                        </Tooltip>
                      )}
                      {data.value !== '' ? iconsObj.SUCCESS : iconsObj.ERROR}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={11} sm={11} md={7} lg={8} xl={8} sx={{ minWidth: '100px', minHeight: '100%', flexdirection: 'column' }}>
          <Paper sx={{ width: '100%', boxShadow: 6, minHeight: '40rem', height: '100%' }}>
            <Grid container sx={{ p: 2 }}>
              <Grid item>
                <Typography sx={{ fontWeight: 700 }} component='div' display='inline'>
                  Possible dosage adjustment needs
                </Typography>
                <Tooltip placement='right' title="Hepatic impairement, age, weight have not been completely evaluated due to incomplete patient's data.">
                  <InfoOutlinedIcon fontSize='small' color='disabled' sx={{ ml: 0.5 }} />
                </Tooltip>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', height: '90%' }}>
              <Table columns={columns} rows={props.dosageAdjustmentData} getRowId={(row) => row.interaction_checker} noRowsText='No interactions found' />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {spcOpen && <SpcDialog open={spcOpen} setOpen={spcSetOpen} title={activeSubstance} interactionChecker={activeSubstance} />}
    </React.Fragment>
  )
}

export default DosageAdjusment
