import React, { useState } from 'react'

import { NavLink } from 'react-router-dom'
import { ListItemText, AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, Avatar, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { ThemeProps } from '../App'
import LoginDialog from './dialogs/LoginDialog'
import { useAuth } from '../context/AuthContext'

type Props = {
  changeMode: () => void
  theme: ThemeProps
}

const pages = [
  { title: 'Home', key: 'home', href: '/' },
  { title: 'Rest API', key: 'apidocs', href: '/rest-api' },
  { title: 'Contact Us', key: 'contactus', href: '/contact-us' },
  { title: 'About Us', key: 'aboutus', href: '/about-us' },
]

const NavBar: React.FC<Props> = ({ changeMode, theme }) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)

  const { isUserAuthenticated, logout } = useAuth()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const onLogout = () => {
    setAnchorElUser(null)
    logout()
  }

  return (
    <React.Fragment>
      <AppBar position='fixed' style={{ backgroundColor: '#212529' }}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters sx={{ px: 3 }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {pages.map((page) => (
                  <MenuItem component={NavLink} to={page.href} key={page.key} onClick={handleCloseNavMenu}>
                    <ListItemText>{page.title}</ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant='h5'
              noWrap
              component='a'
              href=''
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Medical Analyses
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button component={NavLink} to={page.href} key={page.key} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page.title}
                </Button>
              ))}
            </Box>
            <IconButton sx={{ mx: 2 }} onClick={() => changeMode()} color='inherit'>
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {isUserAuthenticated === false ? (
              <MenuItem component='a' key='login-btn' onClick={() => setOpenLoginModal(true)}>
                <Typography textAlign='center'>Login</Typography>
              </MenuItem>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title='Open settings'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar src='/static/images/avatar/2.jpg' />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key={'logout'} onClick={onLogout}>
                    <Typography textAlign='center'>Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <LoginDialog open={openLoginModal} setOpen={setOpenLoginModal} />
    </React.Fragment>
  )
}

export default NavBar
