import React from 'react'

import { Tooltip, Chip, Stack, Link, Typography } from '@mui/material'
import { iconsObj } from '../icons/Icons'
import { ActiveSubstance } from '../types/DrugsInteractions'

type ActiveSubstanceCellProps = {
  activeSubstances: ActiveSubstance[]
}

type ActiveSubstanceSpcCellProps = {
  activeSubstance: string
  setActiveSubstance: (activeSubstance: string) => void
  spcSetOpen: (open: boolean) => void
  hasSpcs: boolean
}

const ActiveSubstanceCell: React.FC<ActiveSubstanceCellProps> = (props) => {
  return props.activeSubstances.length > 0 ? (
    <Stack spacing={2}>
      {props.activeSubstances.map((activeSubstance, index) => (
        <Tooltip
          placement='top'
          key={`${index}-${activeSubstance.drug}`}
          title={activeSubstance.has_interaction ? null : `We have not interaction data for ${activeSubstance.drug}.`}
        >
          <Chip
            size='small'
            label={activeSubstance.drug}
            icon={activeSubstance.has_interaction ? iconsObj.SUCCESS : iconsObj.WARNING}
            sx={{ justifyContent: 'left' }}
          />
        </Tooltip>
      ))}
    </Stack>
  ) : (
    <Tooltip placement='right' title='Unknown active substance'>
      {iconsObj.ERROR}
    </Tooltip>
  )
}

export const ActiveSubstanceSpcCell: React.FC<ActiveSubstanceSpcCellProps> = (props) => {
  const onLinkClick = () => {
    props.setActiveSubstance(props.activeSubstance)
    props.spcSetOpen(true)
  }

  return props.activeSubstance !== '' ? (
    <React.Fragment>
      {props.hasSpcs == true ? (
        <Tooltip placement='right' title='Show SmPCs'>
          <Link underline='none' onClick={onLinkClick} sx={{ cursor: 'pointer' }}>
            {props.activeSubstance}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip placement='right' title='SPCs not available'>
          <Typography variant='body2' color='text.secondary'>
            {props.activeSubstance}
          </Typography>
        </Tooltip>
      )}
    </React.Fragment>
  ) : (
    <Tooltip placement='right' title='Unknown active substance'>
      {iconsObj.ERROR}
    </Tooltip>
  )
}

export default ActiveSubstanceCell
