import React from 'react'
import { Dialog, DialogTitle, Stack, DialogContent, DialogActions, Paper, Typography, Divider, Button, Grid, IconButton } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'

import { iconsObj } from '../../icons/Icons'
import { basicPatientTextField, basicPatientRadioForm, laboratoryResultsTextField, diagnosisAndOtherDataRadioForm, PatientFormData } from '../Constants'
import { CalculationProps, OptionalParamsProps } from '../../types/MedicalCalculations'
import DecimalInputField from '../DecimalInputField'
import OptionsInputField from '../OptionsInputField'

type Props = CalculationProps & {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  requiredParams: ObjectProps
  optionalParams?: OptionalParamsProps[]
}

type ObjectProps = {
  [key: string]: string
}

type BasicPatientData = {
  type: string
  label: string
  units: string
  id: string
  value: string
}

type RadioOption = {
  label: string
  value: string
  id: string
}

type BasicPatientRadioField = {
  type: string
  label: string
  id: string
  options: RadioOption[]
  defaultValue: string
}

const CalculationDialog: React.FC<Props> = (props) => {
  const { control, setValue, handleSubmit } = useForm<PatientFormData>()

  const handleClose = () => {
    props.setOpen(false)
  }

  const onSubmit: SubmitHandler<PatientFormData> = (data) => {
    const formData: PatientFormData = { ...props.formData, ...data }
    props.setFormData(formData)
    handleClose()
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='lg'
      aria-labelledby='modify-calculation-patient-data-dialog'
      aria-describedby='modify-calculation-patient-data-dialog'
    >
      <DialogTitle>
        <Stack direction='row' spacing={1}>
          <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
            {iconsObj.MODIFY}
          </Paper>
          <Typography variant='h5'> {props.title}</Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        <Divider sx={{ my: 4 }}>Basic patient's data</Divider>
        <Grid container spacing={3} justifyContent={'left'}>
          {Object.values(basicPatientTextField).map((data: BasicPatientData, index) => {
            return (
              <React.Fragment key={`${data.id}-${index}`}>
                {data.id in props.requiredParams && (
                  <Grid item xs={11} sm={6} md={6} lg={4} xl={3}>
                    <DecimalInputField
                      id={data.id}
                      label={data.label}
                      units={data.units}
                      defaultValue={props.requiredParams[data.id]}
                      control={control}
                      setValue={setValue}
                    />
                  </Grid>
                )}
                {props.optionalParams?.some((param) => param.params.includes(data.id)) && (
                  <Grid item xs={11} sm={6} md={6} lg={4} xl={3}>
                    <DecimalInputField
                      id={data.id}
                      label={data.label}
                      units={data.units}
                      defaultValue={props.formData[data.id as keyof PatientFormData]}
                      control={control}
                      setValue={setValue}
                    />
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>

        <Grid container spacing={3} justifyContent={'left'} sx={{ mt: 0 }}>
          {Object.values(basicPatientRadioForm).map((data, index) => {
            return (
              <React.Fragment key={`${data.id}-${index}`}>
                {data.id in props.requiredParams && (
                  <Grid item key={`${data.id}-grid`} xs={11} sm={6} md={6} lg={4} xl={3}>
                    <OptionsInputField id={data.id} label={data.label} defaultValue={props.requiredParams[data.id]} options={data.options} control={control} />
                  </Grid>
                )}
                {props.optionalParams?.some((param) => param.params.includes(data.id)) && (
                  <Grid item key={`${data.id}-grid`} xs={11} sm={6} md={6} lg={4} xl={3}>
                    <OptionsInputField
                      id={data.id}
                      label={data.label}
                      defaultValue={props.formData[data.id as keyof PatientFormData]}
                      options={data.options}
                      control={control}
                    />
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>

        <Divider sx={{ my: 4 }}>Laboratory results</Divider>

        <Grid container spacing={3} justifyContent={'left'}>
          {Object.values(laboratoryResultsTextField).map((data: BasicPatientData, index) => {
            return (
              <React.Fragment key={`${data.id}-${index}`}>
                {data.id in props.requiredParams && (
                  <Grid item key={`${data.id}-grid`} xs={11} sm={6} md={6} lg={4} xl={3}>
                    <DecimalInputField
                      id={data.id}
                      label={data.label}
                      units={data.units}
                      defaultValue={props.requiredParams[data.id]}
                      control={control}
                      setValue={setValue}
                    />
                  </Grid>
                )}
                {props.optionalParams?.some((param) => param.params.includes(data.id)) && (
                  <Grid item key={`${data.id}-grid`} xs={11} sm={6} md={6} lg={4} xl={3}>
                    <DecimalInputField
                      id={data.id}
                      label={data.label}
                      units={data.units}
                      defaultValue={props.formData[data.id as keyof PatientFormData]}
                      control={control}
                      setValue={setValue}
                    />
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>

        <Divider sx={{ my: 4 }}>Diagnosis and other data</Divider>

        <Grid container spacing={3} justifyContent={'left'} sx={{ mt: 0 }}>
          {Object.values(diagnosisAndOtherDataRadioForm).map((data: BasicPatientRadioField, index) => {
            return (
              <React.Fragment key={`${data.id}-${index}`}>
                {data.id in props.requiredParams && (
                  <Grid item key={data.id} xs={11} sm={6} md={6} lg={4} xl={4}>
                    <OptionsInputField id={data.id} label={data.label} defaultValue={props.requiredParams[data.id]} options={data.options} control={control} />
                  </Grid>
                )}
                {props.optionalParams?.some((param) => param.params.includes(data.id)) && (
                  <Grid item key={`${data.id}-grid`} xs={11} sm={6} md={6} lg={4} xl={3}>
                    <OptionsInputField
                      id={data.id}
                      label={data.label}
                      defaultValue={props.formData[data.id as keyof PatientFormData]}
                      options={data.options}
                      control={control}
                    />
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mx: 3, mb: 2, justifyContent: 'flex-end' }}>
        <Button variant='outlined' onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CalculationDialog
