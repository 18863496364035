import React from 'react'

import { Grid, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { blue } from '@mui/material/colors'

const Footer: React.FC = () => {
  const linkColor = blue[400]

  return (
    <Paper sx={{ pt: 5, pb: 2, marginTop: 'calc(20%)', bottom: 0, width: '100%' }} component='footer' square>
      <Grid container>
        <Grid container item xs={12} sm={4} xl={4} justifyContent='center' alignItems='flex-start'>
          <Grid item>
            <Typography variant='h6' color='text.primary' fontSize={16}>
              About
            </Typography>
            <Typography variant='body1' color='text.secondary' fontSize={15}>
              Learn more&nbsp;
              <Link to='/about-us' style={{ textDecoration: 'none', color: linkColor }}>
                About us&nbsp;
              </Link>
              and our mission.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={4} xl={3} justifyContent='center' alignItems='flex-start'>
          <Grid item>
            <Typography variant='h6' color='text.primary' fontSize={16}>
              Services
            </Typography>
            <Typography variant='body1' color='text.secondary' fontSize={15}>
              Discover the power of our&nbsp;
              <Link to='/rest-api' style={{ textDecoration: 'none', color: linkColor }}>
                Rest API.
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={4} xl={5} justifyContent='center' alignItems='flex-start'>
          <Grid item>
            <Typography variant='h6' color='text.primary' fontSize={16}>
              Contact
            </Typography>
            <Typography variant='body1' color='text.secondary' fontSize={15}>
              <Link to='/contact-us' style={{ textDecoration: 'none', color: linkColor }}>
                Contact us&nbsp;
              </Link>
              for more information.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' height='40%' mt={5}>
        <Grid item>
          <Typography variant='caption' color='text.secondary'>
            Copyright © 2024, Medical Analyses |&nbsp;
            <Link to='/terms-conditions' style={{ textDecoration: 'none', color: linkColor }}>
              Terms & Conditions
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Footer
