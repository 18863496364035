import { useState, useMemo, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material'
import { SnackbarProvider } from 'notistack'

import './App.css'
import CssBaseline from '@mui/material/CssBaseline'
import NavBar from './components/NavBar'
import Home from './pages/Home'
import PatientResults from './pages/PatientResults'
import { AuthProvider } from './context/AuthContext'
import ProtectedRoute from './components/ProtectedRoute'
import TermsAndConditions from './pages/TermsConditions'
import Contact from './pages/Contact'

type PaletteProps = {
  mode: string
}

export type ThemeProps = {
  palette: PaletteProps
}

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false)

  const changeMode = () => {
    setDarkMode(!darkMode)
    localStorage.setItem('darkMode', (!darkMode).toString())
  }

  useEffect(() => {
    if (localStorage.getItem('darkMode')) {
      if (localStorage.getItem('darkMode') === 'true') {
        setDarkMode(true)
      } else {
        setDarkMode(false)
      }
    }
  }, [])

  const theme = useMemo(() => createTheme({ palette: { mode: darkMode ? 'dark' : 'light' } }), [darkMode])

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3} preventDuplicate={true} autoHideDuration={7000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          <AuthProvider>
            <Router>
              <NavBar changeMode={changeMode} theme={theme} />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route
                  path='/patient-results'
                  element={
                    <ProtectedRoute>
                      <PatientResults />
                    </ProtectedRoute>
                  }
                />
                <Route path='/contact-us' element={<Contact />} />
                <Route path='/terms-conditions' element={<TermsAndConditions />} />
              </Routes>
            </Router>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
