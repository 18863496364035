import React from 'react'
import { Container, Grid, Skeleton, Stack } from '@mui/material'

const MedicalResultsSkeleton: React.FC = () => {
  return (
    <Container maxWidth='xl' sx={{ mt: 12 }}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={11} lg={10} xl={11}>
          <Stack spacing={3}>
            <Skeleton variant='rounded' width='100%' height={100} />
            <Skeleton variant='rounded' width='100%' height={80} />
          </Stack>
          <Stack direction='row' justifyContent='center' pt={3}>
            <Grid container item justifyContent='center' width='100%' spacing={3} sx={{ minHeight: 'calc(65vh)' }}>
              <Grid item xs={11} sm={11} md={10} lg={5} xl={5}>
                <Skeleton variant='rounded' sx={{ minHeight: '40rem' }} />
              </Grid>
              <Grid item xs={11} sm={11} md={10} lg={7} xl={7}>
                <Skeleton variant='rounded' sx={{ minHeight: '40rem' }} />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default MedicalResultsSkeleton
