import React from 'react'
import { TextField, TextFieldProps, InputAdornment } from '@mui/material'
import { Controller, Control, UseFormSetValue } from 'react-hook-form'

import { PatientFormData } from './Constants'

type Props = TextFieldProps & {
  id: string
  defaultValue: string
  units: string
  setValue: UseFormSetValue<PatientFormData>
  control: Control<PatientFormData>
}

const DecimalInputField: React.FC<Props> = (props) => {
  const handleInputChange = (name: keyof PatientFormData, value: string) => {
    value = value.replace(/[^0-9,.]/g, '').replace(/,/g, '.')
    props.setValue(name, value)
  }

  return (
    <Controller
      key={props.id}
      name={props.id as keyof PatientFormData}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <TextField
          fullWidth
          size='small'
          id={props.id}
          label={props.label}
          value={field.value}
          inputProps={{
            type: 'decimal',
            inputMode: 'decimal',
            pattern: '^([0-9]+\\.?[0-9]*|\\.[0-9]+)$',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <div dangerouslySetInnerHTML={{ __html: props.units }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleInputChange(props.id as keyof PatientFormData, e.target.value)}
        />
      )}
    />
  )
}

export default DecimalInputField
