export const severityColors = {
  Contraindicated: '#383838',
  Major: '#ff5555',
  Moderate: '#fa8231',
  Minor: '#f7b731',
}

export type InteractioSeverities = 'Contraindicated' | 'Major' | 'Moderate' | 'Minor'

export type PatientFormData = {
  medical_anamnesis: string
  age: string
  height: string
  weight: string
  waist_circumference: string
  systolic_pressure: string
  gender: string
  race: string
  smoker: string
  alcohol_use: string
  glucose_s: string
  creatinine_s: string
  gfr: string
  cystatin_c_s: string
  ast: string
  alt: string
  gmt: string
  alp: string
  bilirubin_s: string
  cholesterol: string
  hdl_cholesterol: string
  non_hdl_cholesterol: string
  triglycerides: string
  albumin_s: string
  creatine_kinase: string
  natrium_s: string
  qt_inr: string
  creatinine_u: string
  albumine_u: string
  albumine_u_creatinine_u: string
  hypertension: string
  hyperglycemia: string
  diabetes_history: string
  chronic_liver_disease_cirrhosis: string
  chronic_heart_failure_history: string
  stroke_history: string
  tia_history: string
  thromboembolism_history: string
  vascular_disease_history: string
  labile_inr: string
  hyperlipidemia_pharmacologic_treatment: string
  medication_predisposing_to_bleeding: string
  prior_major_bleeding_or_predisposition_to_bleeding: string
  ascites: string
  encephalopathy: string
  risk_region: string
  creatinine_kinase: string
  child_pugh_score: string
}

export const patientFormDataNames: PatientFormData = {
  medical_anamnesis: 'Medical anamnesis',
  age: 'Age',
  height: 'Height',
  weight: 'Weight',
  waist_circumference: 'Waist circumference',
  systolic_pressure: 'Systolic pressure',
  gender: 'Gender',
  race: 'Race',
  smoker: 'Smoker',
  alcohol_use: 'Alcohol use',
  glucose_s: 'Glucose - S',
  creatinine_s: 'Creatinine - S',
  gfr: 'GFR',
  cystatin_c_s: 'Cystatin C - S',
  ast: 'AST',
  alt: 'ALT',
  gmt: 'GMT',
  alp: 'ALP',
  bilirubin_s: 'Bilirubin - S',
  cholesterol: 'Cholesterol total',
  hdl_cholesterol: 'HDL - Cholesterol',
  non_hdl_cholesterol: 'Non-HDL Cholesterol',
  triglycerides: 'Triglycerides',
  albumin_s: 'Albumin - S',
  creatine_kinase: 'Creatine kinase',
  natrium_s: 'Natrium - S',
  qt_inr: 'QT INR',
  creatinine_u: 'Creatinine - U',
  albumine_u: 'Albumine - U',
  albumine_u_creatinine_u: 'Albumine - U/Creatinine - U',
  hypertension: 'Hypertension',
  hyperglycemia: 'Hyperglycemia',
  diabetes_history: 'Diabetes history',
  chronic_liver_disease_cirrhosis: 'Chronic liver disease / cirrhosis',
  chronic_heart_failure_history: 'Chronic heart failure history',
  stroke_history: 'Stroke history',
  tia_history: 'TIA history',
  thromboembolism_history: 'Thromboembolism history',
  vascular_disease_history: 'Vascular disease history',
  labile_inr: 'Labile INR',
  hyperlipidemia_pharmacologic_treatment: 'Hyperlipidemia pharmacologic treatment',
  medication_predisposing_to_bleeding: 'Medication predisposing to bleeding',
  prior_major_bleeding_or_predisposition_to_bleeding: 'Prior major bleeding or predisposition to bleeding',
  ascites: 'Ascites',
  encephalopathy: 'Encephalopathy',
  risk_region: 'Risk region',
  creatinine_kinase: 'Creatine kinase',
  child_pugh_score: 'Child Pugh score',
}

export const basicPatientTextField = {
  age: { type: 'textField', label: 'Age', units: 'yrs', id: 'age', value: '' },
  height: { type: 'textField', label: 'Height', units: 'cm', id: 'height', value: '' },
  weight: { type: 'textField', label: 'Weight', units: 'kg', id: 'weight', value: '' },
  waist_circumference: { type: 'textField', label: 'Waist circumference', units: 'cm', id: 'waist_circumference', value: '' },
  systolic_pressure: { type: 'textField', label: 'Systolic pressure', units: 'mmHg', id: 'systolic_pressure', value: '' },
}

export const basicPatientRadioForm = {
  gender: {
    type: 'radio',
    label: 'Gender',
    id: 'gender',
    options: [
      { label: 'Male', value: 'male', id: 'gender-man' },
      { label: 'Female', value: 'female', id: 'gender-woman' },
    ],
    defaultValue: '',
  },
  race: {
    type: 'radio',
    label: 'Race',
    id: 'race',
    options: [
      { label: 'Black', value: 'black', id: 'race-black' },
      { label: 'Others', value: 'others', id: 'race-others' },
    ],
    defaultValue: '',
  },
  smoker: {
    type: 'radio',
    label: 'Smoker',
    id: 'smoker',
    options: [
      { label: 'Yes', value: 'yes', id: 'smoker-yes' },
      { label: 'No', value: 'no', id: 'smoker-no' },
    ],
    defaultValue: '',
  },
  alcohol_use: {
    type: 'radio',
    label: 'Alcohol use',
    id: 'alcohol_use',
    options: [
      { label: 'Yes', value: 'yes', id: 'alcohol-yes' },
      { label: 'No', value: 'no', id: 'alcohol-no' },
    ],
    defaultValue: '',
  },
}

export const laboratoryResultsTextField = {
  glucose_s: { type: 'basic', label: 'Glucose - S', units: 'mmol/l', id: 'glucose_s', value: '' },
  creatinine_s: { type: 'basic', label: 'Creatinine - S', units: 'umol/l', id: 'creatinine_s', value: '' },
  gfr: { type: 'basic', label: 'GFR', units: 'ml/s/1.73m<sup>2</sup>', id: 'gfr', value: '' },
  cystatin_c_s: { type: 'basic', label: 'Cystatin C - S', units: 'mg/l', id: 'cystatin_c_s', value: '' },
  ast: { type: 'basic', label: 'AST', units: 'ukat/l', id: 'ast', value: '' },
  alt: { type: 'basic', label: 'ALT', units: 'ukat/l', id: 'alt', value: '' },
  gmt: { type: 'basic', label: 'GMT', units: 'ukat/l', id: 'gmt', value: '' },
  alp: { type: 'basic', label: 'ALP', units: 'ukat/l', id: 'alp', value: '' },
  bilirubin_s: { type: 'basic', label: 'Bilirubin - S', units: 'umol/l', id: 'bilirubin_s', value: '' },
  cholesterol: { type: 'basic', label: 'Cholesterol total', units: 'mmol/l', id: 'cholesterol', value: '' },
  hdl_cholesterol: { type: 'basic', label: 'HDL - Cholesterol', units: 'mmol/l', id: 'hdl_cholesterol', value: '' },
  non_hdl_cholesterol: { type: 'basic', label: 'Non-HDL Cholesterol', units: 'mmol/l', id: 'non_hdl_cholesterol', value: '' },
  triglycerides: { type: 'basic', label: 'Triglycerides', units: 'mmol/l', id: 'triglycerides', value: '' },
  albumin_s: { type: 'basic', label: 'Albumin - S', units: 'g/l', id: 'albumin_s', value: '' },
  creatine_kinase: { type: 'basic', label: 'Creatine kinase', units: 'ukat/l', id: 'creatine_kinase', value: '' },
  natrium_s: { type: 'basic', label: 'Natrium - S', units: 'mmol/l', id: 'natrium_s', value: '' },
  qt_inr: { type: 'basic', label: 'QT INR', units: '', id: 'qt_inr', value: '' },
  creatinine_u: { type: 'basic', label: 'Creatinine - U', units: 'umol/l', id: 'creatinine_u', value: '' },
  albumine_u: { type: 'basic', label: 'Albumine - U', units: 'mg/l', id: 'albumine_u', value: '' },
  albumine_u_creatinine_u: { type: 'basic', label: 'Albumine - U/Creatinine - U', units: 'mg/mmol', id: 'albumine_u_creatinine_u', value: '' },
}

export const diagnosisAndOtherDataRadioForm = {
  hypertension: {
    type: 'radio',
    label: 'Hypertension',
    id: 'hypertension',
    options: [
      { label: 'Yes', value: 'yes', id: 'hypertension-yes' },
      { label: 'No', value: 'no', id: 'hypertension-no' },
    ],
    defaultValue: '',
  },
  hyperglycemia: {
    type: 'radio',
    label: 'Hyperglycemia',
    id: 'hyperglycemia',
    options: [
      { label: 'Yes', value: 'yes', id: 'hyperglycemia-yes' },
      { label: 'No', value: 'no', id: 'hyperglycemia-no' },
    ],
    defaultValue: '',
  },
  diabetes_history: {
    type: 'radio',
    label: 'Diabetes history',
    id: 'diabetes_history',
    options: [
      { label: 'Yes', value: 'yes', id: 'diabetes-history-yes' },
      { label: 'No', value: 'no', id: 'diabetes-history-no' },
    ],
    defaultValue: '',
  },
  chronic_liver_disease_cirrhosis: {
    type: 'radio',
    label: 'Chronic liver disease / cirrhosis',
    id: 'chronic_liver_disease_cirrhosis',
    options: [
      { label: 'Yes', value: 'yes', id: 'chronic-liver-disease-yes' },
      { label: 'No', value: 'no', id: 'chronic-liver-disease-no' },
    ],
    defaultValue: '',
  },
  chronic_heart_failure_history: {
    type: 'radio',
    label: 'Chronic heart failure history',
    id: 'chronic_heart_failure_history',
    options: [
      { label: 'Yes', value: 'yes', id: 'chronic-heart-failure-history-yes' },
      { label: 'No', value: 'no', id: 'chronic-heart-failure-history-no' },
    ],
    defaultValue: '',
  },
  stroke_history: {
    type: 'radio',
    label: 'Stroke history',
    id: 'stroke_history',
    options: [
      { label: 'Yes', value: 'yes', id: 'stroke-history-yes' },
      { label: 'No', value: 'no', id: 'stroke-history-no' },
    ],
    defaultValue: '',
  },
  tia_history: {
    type: 'radio',
    label: 'TIA history',
    id: 'tia_history',
    options: [
      { label: 'Yes', value: 'yes', id: 'tia-history-yes' },
      { label: 'No', value: 'no', id: 'tia-history-no' },
    ],
    defaultValue: '',
  },
  thromboembolism_history: {
    type: 'radio',
    label: 'Thromboembolism history',
    id: 'thromboembolism_history',
    options: [
      { label: 'Yes', value: 'yes', id: 'thromboembolism-history-yes' },
      { label: 'No', value: 'no', id: 'thromboembolism-history-no' },
    ],
    defaultValue: '',
  },
  vascular_disease_history: {
    type: 'radio',
    label: 'Vascular disease history',
    id: 'vascular_disease_history',
    options: [
      { label: 'Yes', value: 'yes', id: 'vascular-disease-history-yes' },
      { label: 'No', value: 'no', id: 'vascular-disease-history-no' },
    ],
    defaultValue: '',
  },
  labile_inr: {
    type: 'radio',
    label: 'Labile INR',
    id: 'labile_inr',
    options: [
      { label: 'Yes', value: 'yes', id: 'labile-inr-yes' },
      { label: 'No', value: 'no', id: 'labile-inr-no' },
    ],
    defaultValue: '',
  },
  hyperlipidemia_pharmacologic_treatment: {
    type: 'radio',
    label: 'Hyperlipidemia pharmacologic treatment',
    id: 'hyperlipidemia_pharmacologic_treatment',
    options: [
      { label: 'Yes', value: 'yes', id: 'hyperlipidemia-pharmacologic-treatment-yes' },
      { label: 'No', value: 'no', id: 'hyperlipidemia-pharmacologic-treatment-no' },
    ],
    defaultValue: '',
  },
  medication_predisposing_to_bleeding: {
    type: 'radio',
    label: 'Medication predisposing to bleeding',
    id: 'medication_predisposing_to_bleeding',
    options: [
      { label: 'Yes', value: 'yes', id: 'medication-predisposing-to-bleeding-yes' },
      { label: 'No', value: 'no', id: 'medication-predisposing-to-bleeding-no' },
    ],
    defaultValue: '',
  },
  prior_major_bleeding_or_predisposition_to_bleeding: {
    type: 'radio',
    label: 'Prior major bleeding or predisposition to bleeding',
    id: 'prior_major_bleeding_or_predisposition_to_bleeding',
    options: [
      { label: 'Yes', value: 'yes', id: 'prior-major-bleeding-or-predisposition-to-bleeding' },
      { label: 'No', value: 'no', id: 'prior-major-bleeding-or-predisposition-to-bleeding-no' },
    ],
    defaultValue: '',
  },
  ascites: {
    type: 'radio',
    label: 'Ascites',
    id: 'ascites',
    options: [
      { label: 'Absent', value: 'absent', id: 'ascites-absent' },
      { label: 'Slight', value: 'slight', id: 'ascites-slight' },
      { label: 'Moderate', value: 'moderate', id: 'ascites-moderate' },
    ],
    defaultValue: '',
  },
  encephalopathy: {
    type: 'radio',
    label: 'Encephalopathy',
    id: 'encephalopathy',
    options: [
      { label: 'No', value: 'no', id: 'encephalopathy-no' },
      { label: 'Grade 1-2', value: 'grade_1_2', id: 'encephalopathy-grade-1-2' },
      { label: 'Grade 3-4', value: 'grade_3_4', id: 'encephalopathy-grade-3-4' },
    ],
    defaultValue: '',
  },
  risk_region: {
    type: 'radio',
    label: 'Risk region',
    id: 'risk_region',
    options: [
      { label: 'A', value: 'a', id: 'risk-region-a' },
      { label: 'B', value: 'b', id: 'risk-region-b' },
      { label: 'C', value: 'c', id: 'risk-region-c' },
      { label: 'D', value: 'd', id: 'risk-region-d' },
    ],
    defaultValue: '',
  },
  child_pugh_score: {
    type: 'radio',
    label: 'Child-Pugh SCORE',
    id: 'child_pugh_score',
    options: [
      { label: 'No', value: 'No', id: 'child-pugh-score-no' },
      { label: 'A', value: 'A', id: 'child-pugh-score-a' },
      { label: 'B', value: 'B', id: 'child-pugh-score-b' },
      { label: 'C', value: 'C', id: 'child-pugh-score-c' },
    ],
    defaultValue: '',
  },
}
