import React from 'react'
import { Container, Typography, Grid, Link } from '@mui/material'
import Footer from '../components/Footer'

const Contact: React.FC = () => {
  return (
    <React.Fragment>
      <Container maxWidth='md' sx={{ mt: 30, height: '23vh' }}>
        <Grid container direction='column' justifyContent='center' spacing={2}>
          <Grid item>
            <Typography variant='h4' align='center'>Contact the Medical Analyses team</Typography>
          </Grid>
          <Grid item xl={6} mb={4}>
            <Typography variant='body1' color='text.secondary' align='center'>
              We are here to answer your questions or address any issues you may have. Please click <Link href="mailto:contact@medicalanalyses.com">here</Link> or send an email to <Link href="mailto:contact@medicalanalyses.com">contact@medicalanalyses.com</Link>, and our team will get back to you as soon as possible.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Contact
