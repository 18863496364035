import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../context/AuthContext'

type Props = {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { isUserAuthenticated } = useAuth()

  // const authenticated = authToken !== null && authToken !== 'null' && authToken !== undefined && authToken.length > 0

  if (!isUserAuthenticated) {
    return <Navigate to='/' replace />
  }
  return <React.Fragment>{children}</React.Fragment>
}

export default ProtectedRoute
