import React, { useEffect, useState } from 'react'

import { CalculationProps, RequiredParamsProps } from '../../types/MedicalCalculations'
import CalculationCard from './CalculationCard'
import { useAuth } from '../../context/AuthContext'

const SodiumCorrectionForHyperglycemia: React.FC<CalculationProps> = (props) => {
  const [data, setData] = useState<string | undefined>(undefined)
  const [requiredParams, setRequiredParams] = useState<RequiredParamsProps>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { backendRequest } = useAuth()

  useEffect(() => {
    setIsLoading(true)
    const reqParamsObj = {
      glucose_s: props.formData.glucose_s,
      natrium_s: props.formData.natrium_s,
    }
    setRequiredParams(reqParamsObj)

    const allParamsPresent = Object.values(reqParamsObj).every((value) => {
      if (value === null || value === undefined || value === '') {
        return false
      }
      return true
    })

    const getData = async () => {
      if (allParamsPresent) {
        const response = await backendRequest({
          method: 'GET',
          endpoint: '/api/medical-calculations/sodium-correction-for-hyperglycemia',
          requiresAuth: true,
          params: {
            glucose_s: props.formData.glucose_s,
            natrium_s: props.formData.natrium_s,
          },
        })
        return response
      } else {
        return undefined
      }
    }
    getData().then((response) => {
      if (response && response.status === 200) {
        setData(response.data)
      } else {
        setData(undefined)
      }
      setIsLoading(false)
    })
  }, [props.formData])

  return (
    <CalculationCard
      title='Sodium correction for hyperglycemia'
      value={data}
      units='mmol/l'
      requiredParams={requiredParams}
      isLoading={isLoading}
      formData={props.formData}
      setFormData={props.setFormData}
    />
  )
}

export default SodiumCorrectionForHyperglycemia
