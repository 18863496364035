import React, { useEffect, useRef, useState } from 'react'

import { Typography, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Box, IconButton } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { useAuth } from '../../context/AuthContext'
import { iconsObj } from '../../icons/Icons'
import SpcSkeleton from '../SpcSkeleton'
import capitalizeFirstLetter from '../scripts/CapitalizeFirstLetter'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  interactionChecker: string
}

type ProductionNameResponse = {
  id: number
  production_name: string
}

type SpcResponse = {
  interaction_checker: string
  name_of_the_medical_product: string
  qualitative_and_quantitative_composition: string
  pharmaceutical_form: string
  terapeutic_indications: string
  posology_and_method_of_administration: string
  contraindications: string
  special_warnings_and_precautions_for_use: string
  interactions_with_other_medicinal_products_and_other_forms_of_interaction: string
  fertility_pregnancy_and_lactation: string
  effects_on_ability_to_drive_and_use_machines: string
  undesirable_effects: string
  overdose: string
  pharmacodynamic_properties: string
  pharmacokinetic_properties: string
  preclinical_safety_data: string
  list_of_excipients: string
  incompatibilities: string
  shelf_life: string
  special_precautions_for_storage: string
  nature_and_contents_of_container: string
  special_precautions_for_disposal_and_other_handling: string
  marketing_authorisation_holder: string
  marketing_authorisation_numbers: string
  data_of_first_authorisation_renewal_of_the_authorisation: string
  date_of_revision_of_the_text: string
}

const SpcDialog: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [productionNames, setProductionNames] = useState<ProductionNameResponse[]>([])
  const [spc, setSpc] = useState<SpcResponse>({} as SpcResponse)

  const [selectedProductionId, setSelectedProductionId] = React.useState<string>('')

  const scrollableBoxRef = useRef<HTMLDivElement>(null)

  const { backendRequest, logout } = useAuth()

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProductionId(event.target.value as string)
  }

  const handleClose = () => {
    props.setOpen(false)
  }

  useEffect(() => {
    setIsLoading(true)
    const getData = async () => {
      const responseProductionNames = await backendRequest({
        method: 'GET',
        endpoint: '/api/spc/production-names',
        requiresAuth: true,
        params: {
          interaction_checker: props.interactionChecker,
        },
      })

      return { responseProductionNames }
    }
    getData()
      .then(({ responseProductionNames }) => {
        if (responseProductionNames.status === 200) {
          setProductionNames(responseProductionNames.data)
          setSelectedProductionId(responseProductionNames.data[0].id)
        } else if (responseProductionNames.status === 401) {
          logout()
        }
      })
      .catch(() => {
        console.log('')
      })
  }, [props.interactionChecker])

  useEffect(() => {
    if (selectedProductionId !== '') {
      setIsLoading(true)
      const getData = async () => {
        const responseSpc = await backendRequest({
          method: 'GET',
          endpoint: '/api/spc',
          requiresAuth: true,
          params: {
            id: selectedProductionId,
          },
        })

        return { responseSpc }
      }
      getData()
        .then(({ responseSpc }) => {
          if (responseSpc.status === 200) {
            setSpc(responseSpc.data)
          } else if (responseSpc.status === 401) {
            logout()
          }
          setIsLoading(false)
        })
        .catch(() => {
          console.log('')
        })

      if (scrollableBoxRef.current) {
        scrollableBoxRef.current.scrollTop = 0
      }
    }
  }, [selectedProductionId])

  return (
    <Dialog
      open={props.open}
      maxWidth='lg'
      fullWidth
      onClose={() => props.setOpen(false)}
      aria-labelledby='spc-modal-modal-title'
      aria-describedby='spc-modal-modal-description'
    >
      <DialogTitle>{capitalizeFirstLetter(props.title)} SPCs</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        <Box>
          <FormControl fullWidth>
            <InputLabel id='spc-select-label'>Choose SPC</InputLabel>
            <Select labelId='spc-select-label' id='spc-select' value={selectedProductionId} label='Choose SPC' onChange={handleChange}>
              {productionNames.map((productionName) => {
                return (
                  <MenuItem value={productionName.id} key={productionName.id}>
                    {productionName.production_name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Box my={4} sx={{ overflowY: 'scroll', height: '60vh' }} ref={scrollableBoxRef}>
            {!isLoading ? (
              <Box mx={1}>
                <Typography variant='h5' mb={2}>
                  1. Name of the Medicinal Product
                </Typography>
                <Typography paragraph>{spc.name_of_the_medical_product}</Typography>
                <Typography variant='h5' mb={2}>
                  2. Qualitative and Quantitative Composition
                </Typography>
                <Typography paragraph>{spc.qualitative_and_quantitative_composition}</Typography>
                <Typography variant='h5' mb={2}>
                  3. Pharmaceutical Form
                </Typography>
                <Typography paragraph>{spc.pharmaceutical_form}</Typography>
                <Typography variant='h5' mb={2}>
                  4. Clinical Particulars
                </Typography>
                <Typography variant='h6' mb={2}>
                  4.1. Therapeutic Indications
                </Typography>
                <Typography paragraph>{spc.terapeutic_indications}</Typography>
                <Typography variant='h6' mb={2}>
                  4.2. Posology and Method of Administration
                </Typography>
                <Typography paragraph>{spc.posology_and_method_of_administration}</Typography>
                <Typography variant='h6' mb={2}>
                  4.3. Contraindications
                </Typography>
                <Typography paragraph>{spc.contraindications}</Typography>
                <Typography variant='h6' mb={2}>
                  4.4. Special Warnings and Precautions for Use
                </Typography>
                <Typography paragraph>{spc.special_warnings_and_precautions_for_use}</Typography>
                <Typography variant='h6' mb={2}>
                  4.5. Interactions with Other Medicinal Products and Other Forms of Interaction
                </Typography>
                <Typography paragraph>{spc.interactions_with_other_medicinal_products_and_other_forms_of_interaction}</Typography>
                <Typography variant='h6' mb={2}>
                  4.6. Fertility, Pregnancy and Lactation
                </Typography>
                <Typography paragraph>{spc.fertility_pregnancy_and_lactation}</Typography>
                <Typography variant='h6' mb={2}>
                  4.7. Effects on Ability to Drive and Use Machines
                </Typography>
                <Typography paragraph>{spc.effects_on_ability_to_drive_and_use_machines}</Typography>
                <Typography variant='h6' mb={2}>
                  4.8. Undesirable Effects
                </Typography>
                <Typography paragraph dangerouslySetInnerHTML={{ __html: spc.undesirable_effects }}></Typography>
                <Typography variant='h6' mb={2}>
                  5. Pharmacological Properties
                </Typography>
                <Typography variant='h6' mb={2}>
                  5.1. Pharmacodynamic Properties
                </Typography>
                <Typography paragraph>{spc.pharmacodynamic_properties}</Typography>
                <Typography variant='h6' mb={2}>
                  5.2. Pharmacokinetic Properties
                </Typography>
                <Typography paragraph>{spc.pharmacokinetic_properties}</Typography>
                <Typography variant='h6' mb={2}>
                  6. Pharmaceutical Particulars
                </Typography>
                <Typography variant='h6' mb={2}>
                  6.1. List of Excipients
                </Typography>
                <Typography paragraph>{spc.list_of_excipients}</Typography>
                <Typography variant='h6' mb={2}>
                  6.2. Incompatibilities
                </Typography>
                <Typography paragraph>{spc.incompatibilities}</Typography>
                <Typography variant='h6' mb={2}>
                  6.3. Shelf Life
                </Typography>
                <Typography paragraph>{spc.shelf_life}</Typography>
                <Typography variant='h6' mb={2}>
                  6.4. Special Precautions for Storage
                </Typography>
                <Typography paragraph>{spc.special_precautions_for_storage}</Typography>
                <Typography variant='h6' mb={2}>
                  6.5. Nature and Contents of Container
                </Typography>
                <Typography paragraph>{spc.nature_and_contents_of_container}</Typography>
                <Typography variant='h6' mb={2}>
                  6.6. Special Precautions for Disposal and Other Handling
                </Typography>
                <Typography paragraph>{spc.special_precautions_for_disposal_and_other_handling}</Typography>
                <Typography variant='h6' mb={2}>
                  7. Marketing Authorisation Holder
                </Typography>
                <Typography paragraph>{spc.marketing_authorisation_holder}</Typography>
                <Typography variant='h6' mb={2}>
                  8. Marketing Authorisation Number(s)
                </Typography>
                <Typography paragraph>{spc.marketing_authorisation_numbers}</Typography>
                <Typography variant='h6' mb={2}>
                  9. Date of First Authorisation/Renewal of the Authorisation
                </Typography>
                <Typography paragraph>{spc.data_of_first_authorisation_renewal_of_the_authorisation}</Typography>
                <Typography variant='h6' mb={2}>
                  10. Date of Revision of the Text
                </Typography>
                <Typography paragraph>{spc.date_of_revision_of_the_text}</Typography>
              </Box>
            ) : (
              <SpcSkeleton />
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SpcDialog
