import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TabPanel, TabList, TabContext } from '@mui/lab'
import { Box, Container, Grid, Tab, Badge, Typography, Accordion, AccordionSummary } from '@mui/material'

import DrugsInteractions from '../components/drugs_interactions/DrugsInteractions'
import MedicalCalculations from '../components/medical_calculations/MedicalCalculations'
import DosageAdjusment from '../components/dosage_adjustment/DosageAdjusment'
import { DosageAdjustment } from '../types/DosageAdjustment'
import PatientDataForm from '../components/PatientDataForm'
import { FoundDrug, NotFoundDrug, InteractionSpc } from '../types/DrugsInteractions'
import { useAuth } from '../context/AuthContext'
import { iconsObj } from '../icons/Icons'
import MedicalResultsSkeleton from '../components/MedicalResultsSkeleton'
import { PatientFormData } from '../components/Constants'
import Footer from '../components/Footer'

const PatientResults: React.FC = () => {
  const location = useLocation()
  const [formData, setFormData] = useState<PatientFormData>(location.state)

  const [childPughScore, setChildPughScore] = useState<string>('')
  const [gfr, setGfr] = useState<string>('')

  const [isLoading, setIsLoading] = useState(true)
  const [drugsInteractionsSpcs, setDrugsInteractionsSpc] = useState<InteractionSpc[] | []>([])
  const [foundDrugs, setFoundDrugs] = useState<FoundDrug[] | []>([])
  const [notFoundDrugs, setNotFoundDrugs] = useState<NotFoundDrug[] | []>([])

  const [dosageAdjustmentData, setDosageAdjustmentData] = useState<DosageAdjustment[] | []>([])

  const [value, setValue] = useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    if (location.state) {
      setFormData(location.state)
    }
  }, [location])

  useEffect(() => {
    setIsLoading(true)

    const childPughScoreValue: string = formData.child_pugh_score !== '' ? formData.child_pugh_score : childPughScore
    const gfrValue: string = formData.gfr !== '' ? formData.gfr : gfr

    const getData = async () => {
      const responseInteractions = await backendRequest({
        method: 'GET',
        endpoint: '/api/drugs-interactions',
        requiresAuth: true,
        params: {
          anamnesis: formData.medical_anamnesis,
        },
      })

      const responseDosageAdjustment = await backendRequest({
        method: 'POST',
        endpoint: '/api/dosage-adjustment',
        requiresAuth: true,
        body: {
          interaction_drugs: responseInteractions.data.interaction_drugs,
          age: formData.age !== '' ? formData.age : null,
          weight: formData.weight !== '' ? formData.weight : null,
          gender: formData.gender !== '' ? formData.gender : null,
          race: formData.race !== '' ? formData.race : null,
          gfr: gfrValue !== '' ? gfrValue : null,
          child_pugh_score: childPughScoreValue !== '' ? childPughScoreValue : null,
          ast: formData.ast !== '' ? formData.ast : null,
          alt: formData.alt !== '' ? formData.alt : null,
          gmt: formData.gmt !== '' ? formData.gmt : null,
          alp: formData.alp !== '' ? formData.alp : null,
          creatinine_s: formData.creatinine_s !== '' ? formData.creatinine_s : null,
          creatinine_kinase: formData.creatinine_kinase !== '' ? formData.creatinine_kinase : null,
          chronic_liver_disease_cirrhosis: formData.chronic_liver_disease_cirrhosis !== '' ? formData.chronic_liver_disease_cirrhosis : null,
          bilirubin_s: formData.bilirubin_s !== '' ? formData.bilirubin_s : null,
          albumin_s: formData.albumin_s !== '' ? formData.albumin_s : null,
          qt_inr: formData.qt_inr !== '' ? formData.qt_inr : null,
          ascites: formData.ascites !== '' ? formData.ascites : null,
          encephalopathy: formData.encephalopathy !== '' ? formData.encephalopathy : null,
        },
      })
      return { responseInteractions, responseDosageAdjustment }
    }
    getData()
      .then(({ responseInteractions, responseDosageAdjustment }) => {
        if (responseInteractions.status === 200) {
          setDrugsInteractionsSpc(responseInteractions.data['interactions_spcs'])
          setFoundDrugs(responseInteractions.data['found_drugs'])
          setNotFoundDrugs(responseInteractions.data['not_found_drugs'])
        }
        if (responseDosageAdjustment.status === 200) {
          setDosageAdjustmentData(responseDosageAdjustment.data)
        } else if (responseDosageAdjustment.status === 401) {
          logout()
        }
        setIsLoading(false)
      })
      .catch(() => {
        console.log('')
      })
  }, [formData, gfr, childPughScore])

  return !isLoading ? (
    <React.Fragment>
      <Container maxWidth='xl' sx={{ mt: 15 }}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={11} lg={10} xl={11}>
            <Accordion sx={{ boxShadow: 10 }}>
              <AccordionSummary expandIcon={iconsObj.EXPAND_MORE} aria-controls='panel1a-content' id='panel1a-header' sx={{ height: '85px' }}>
                <Typography variant='h5'>Patient's data</Typography>
              </AccordionSummary>
              <PatientDataForm formData={formData} />
            </Accordion>
            <Box width='100%' mt={3} typography='body1'>
              <TabContext value={value}>
                <Box borderBottom={1} borderColor='divider'>
                  <TabList onChange={handleChange} aria-label='Medical analysis results' centered variant='fullWidth'>
                    <Tab
                      icon={iconsObj.DRUGS_INTERACTIONS}
                      iconPosition='start'
                      value='1'
                      label={
                        <Badge color='primary' badgeContent={drugsInteractionsSpcs.length} sx={{ p: 1 }}>
                          <Typography variant='body2' fontWeight={700}>
                            Drugs Interactions
                          </Typography>
                        </Badge>
                      }
                    />
                    <Tab
                      icon={iconsObj.DOSAGE_ADJUSTMENT}
                      iconPosition='start'
                      value='2'
                      label={
                        <Badge color='primary' badgeContent={dosageAdjustmentData.length} sx={{ p: 1 }}>
                          <Typography variant='body2' fontWeight={700}>
                            Dosage Adjustment
                          </Typography>
                        </Badge>
                      }
                    />
                    <Tab
                      icon={iconsObj.MEDICAL_CALCULATION}
                      iconPosition='start'
                      value='3'
                      label={
                        <Typography variant='body2' fontWeight={700}>
                          Medical Calculations
                        </Typography>
                      }
                    />
                  </TabList>
                </Box>
                <TabPanel value='1'>
                  <DrugsInteractions drugsInteractionsSpcs={drugsInteractionsSpcs} foundDrugs={foundDrugs} notFoundDrugs={notFoundDrugs} />
                </TabPanel>
                <TabPanel value='2'>
                  <DosageAdjusment formData={formData} dosageAdjustmentData={dosageAdjustmentData} childPughScore={childPughScore} gfr={gfr} />
                </TabPanel>
                <TabPanel value='3'>
                  <MedicalCalculations formData={formData} setFormData={setFormData} setChildPughScore={setChildPughScore} setGfr={setGfr} />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  ) : (
    <MedicalResultsSkeleton />
  )
}

export default PatientResults
