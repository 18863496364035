import React, { useState } from 'react'

import { Grid, Card, CardHeader, CardContent, CardActions, Typography, Chip, Button, Box, Divider } from '@mui/material/'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { CalculationProps, OptionalParamsProps } from '../../types/MedicalCalculations'
import CalculationMadal from '../dialogs/CalculationDialog'
import { PatientFormData, patientFormDataNames } from '../Constants'

type ObjectProps = {
  [key: string]: string
}

type CalculationCardProps = CalculationProps & {
  title: string
  value: string | undefined
  units: string
  requiredParams?: ObjectProps
  optionalParams?: OptionalParamsProps[]
  isLoading: boolean
}

const CalculationCard: React.FC<CalculationCardProps> = ({
  title = '',
  value = undefined,
  units = '',
  requiredParams = {},
  optionalParams = [],
  isLoading = true,
  formData,
  setFormData,
}) => {
  const [open, setOpen] = useState(false)

  return isLoading === false ? (
    <>
      <Card sx={{ minWidth: '100px', minHeight: '100%', boxShadow: 6, display: 'flex', justiyContent: 'space-between', flexDirection: 'column' }}>
        <CardHeader sx={{ pb: 0 }} titleTypographyProps={{ variant: 'subtitle1', fontWeight: 700 }} title={title} />
        <CardContent sx={{ pb: 0 }}>
          <Box>
            <Typography sx={{ fontWeight: 700 }} variant='h5' component='div' display='inline'>
              {value ? value : '--'}{' '}
            </Typography>
            <Typography sx={{ fontWeight: 700 }} variant='h6' component='div' display='inline'>
              <div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: units }} />
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant='body1' sx={{ my: 2 }}>
              Required patient's data
            </Typography>
            <Grid container spacing={1} sx={{ pl: 1 }}>
              {Object.keys(requiredParams).map((param, index) => {
                return (
                  <Grid item key={`${param}-${index}`}>
                    <Chip
                      size='small'
                      label={patientFormDataNames[param as keyof PatientFormData]}
                      color={requiredParams[param] !== '' ? 'success' : 'default'}
                      icon={requiredParams[param] !== '' ? <CheckIcon fontSize='small' /> : <ClearIcon fontSize='small' />}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Grid container spacing={1} sx={{ pl: 1, mt: 0.1 }}>
              {optionalParams.map((obj, index) => {
                return (
                  <Grid item key={`${obj.params}-${index}`} sx={{ mt: 0.2 }}>
                    <Chip
                      size='small'
                      sx={{ height: '100%' }}
                      label={
                        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ my: 0.5 }}>
                          {obj.labels.map((p, j) => {
                            return (
                              <React.Fragment key={`${p}-${j}`}>
                                <Grid item xs>
                                  {p}
                                </Grid>
                                {j !== obj.labels.length - 1 ? <Divider flexItem>OR</Divider> : null}
                              </React.Fragment>
                            )
                          })}
                        </Grid>
                      }
                      color={obj.value === true ? 'success' : 'default'}
                      icon={obj.value === true ? <CheckIcon fontSize='small' /> : <ClearIcon fontSize='small' />}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ marginTop: 'auto', height: '100%', p: 2, justifyContent: 'flex-end' }}>
          <Button color='primary' onClick={() => setOpen(true)}>
            Modify
          </Button>
        </CardActions>
      </Card>
      <CalculationMadal
        open={open}
        setOpen={setOpen}
        title={title}
        requiredParams={requiredParams}
        optionalParams={optionalParams}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  ) : null
}

export default CalculationCard
