import React, { useEffect, useState } from 'react'

import { CalculationProps, OptionalParamsProps } from '../../types/MedicalCalculations'
import CalculationCard from './CalculationCard'
import { useAuth } from '../../context/AuthContext'

const CkdNomenclature: React.FC<CalculationProps> = (props) => {
  const [data, setData] = useState<string | undefined>(undefined)
  const [optionalParams, setOptionalParams] = useState<OptionalParamsProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { backendRequest } = useAuth()

  useEffect(() => {
    const optParams = [
      {
        labels: ['Cystatin C - S AND Age AND Gender', 'Creatinine - S AND Age AND Gender AND Race', 'GFR'],
        params: ['age', 'gender', 'race', 'creatinine_s', 'cystatin_c_s', 'gfr'],
        value:
          (props.formData.age !== '' && props.formData.gender !== '' && props.formData.race !== '' && props.formData.cystatin_c_s !== '') ||
          (props.formData.age !== '' && props.formData.gender !== '' && props.formData.race !== '' && props.formData.creatinine_s !== '') ||
          props.formData.gfr
            ? true
            : false,
      },
      {
        labels: ['Albumine - U AND Creatinine - U', 'Albumine - U / Creatinine - U'],
        params: ['albumine_u', 'creatinine_u', 'albumine_u_creatinine_u'],
        value: (props.formData.albumine_u !== '' && props.formData.creatinine_u !== '') || props.formData.albumine_u_creatinine_u ? true : false,
      },
    ]
    setOptionalParams(optParams)
    setIsLoading(true)

    const optParamsPresent = optParams.every((obj) => {
      if (obj.value === true) {
        return true
      }
      return false
    })

    const getData = async () => {
      if (optParamsPresent) {
        const response = await backendRequest({
          method: 'GET',
          endpoint: '/api/medical-calculations/ckd-nomenclature',
          requiresAuth: true,
          params: {
            age: props.formData.age !== '' ? props.formData.age : null,
            gender: props.formData.gender !== '' ? props.formData.gender : null,
            race: props.formData.race !== '' ? props.formData.race : null,
            creatinine_s: props.formData.creatinine_s !== '' ? props.formData.creatinine_s : null,
            gfr: props.formData.gfr !== '' ? props.formData.gfr : null,
            cystatin_c_s: props.formData.cystatin_c_s !== '' ? props.formData.cystatin_c_s : null,
            albumine_u: props.formData.albumine_u !== '' ? props.formData.albumine_u : null,
            creatinine_u: props.formData.creatinine_u !== '' ? props.formData.creatinine_u : null,
            albumine_u_creatinine_u: props.formData.albumine_u_creatinine_u !== '' ? props.formData.albumine_u_creatinine_u : null,
          },
        })
        return response
      } else {
        return undefined
      }
    }
    getData().then((response) => {
      if (response && response.status === 200) {
        setData(response.data)
      } else {
        setData(undefined)
      }
      setIsLoading(false)
    })
  }, [props.formData])

  return (
    <CalculationCard
      title='CKD nomenclature by KDIGO - 2012'
      value={data}
      units=''
      optionalParams={optionalParams}
      isLoading={isLoading}
      formData={props.formData}
      setFormData={props.setFormData}
    />
  )
}

export default CkdNomenclature
