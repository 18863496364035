import React from 'react'

import { Stack, Typography } from '@mui/material'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'

type TableProps = DataGridProps & {
  rows: DataGridProps['rows']
  columns: DataGridProps['columns']
  getRowId?: DataGridProps['getRowId']
  noRowsText?: string
}

type Props = {
  noRowsText: string
}

const NoRowsOverlay: React.FC<Props> = ({ noRowsText }) => {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      <Typography>{noRowsText}</Typography>
    </Stack>
  )
}

export const Table: React.FC<TableProps> = ({ rows, columns, getRowId, noRowsText = 'No rows' }) => {
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pagination
      disableSelectionOnClick
      rowsPerPageOptions={[100]}
      components={{
        NoRowsOverlay: () => <NoRowsOverlay noRowsText={noRowsText} />,
      }}
      getRowId={getRowId}
      getRowHeight={() => 'auto'}
      density='standard'
      sx={{
        border: 0,
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
      }}
    />
  )
}
