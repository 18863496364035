import React from 'react'

import { Typography, Tooltip, Link, Box } from '@mui/material'
import { iconsObj } from '../icons/Icons'

type Props = {
  firstDrug: string
  secondDrug: string
  hasFirstDrugSpc: boolean
  hasSecondDrugSpc: boolean
  setInteractionChecker: (interactionChecker: string) => void
  spcSetOpen: (open: boolean) => void
}

const InteractionCell: React.FC<Props> = (props) => {
  const onLinkClick = (interactionChecker: string) => {
    props.setInteractionChecker(interactionChecker)
    props.spcSetOpen(true)
  }

  return (
    <React.Fragment>
      {props.hasFirstDrugSpc == true ? (
        <Tooltip placement='right' title='Show SmPCs'>
          <Link underline='none' onClick={() => onLinkClick(props.firstDrug)} sx={{ cursor: 'pointer' }}>
            {props.firstDrug}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip placement='right' title='SPCs not available'>
          <Typography variant='body2' color='text.secondary'>
            {props.firstDrug}
          </Typography>
        </Tooltip>
      )}
      <Box sx={{ '& svg': { fontSize: 20 } }}>&nbsp; {iconsObj.DRUGS_INTERACTIONS} &nbsp;</Box>
      {props.hasSecondDrugSpc == true ? (
        <Tooltip placement='right' title='Show SmPCs'>
          <Link underline='none' onClick={() => onLinkClick(props.secondDrug)} sx={{ cursor: 'pointer' }}>
            {props.secondDrug}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip placement='right' title='SPCs not available'>
          <Typography variant='body2' color='text.secondary'>
            {props.secondDrug}
          </Typography>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

export default InteractionCell
