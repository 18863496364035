import React, { useEffect, useState } from 'react'

import { CalculationProps, RequiredParamsProps } from '../../types/MedicalCalculations'
import CalculationCard from './CalculationCard'
import { useAuth } from '../../context/AuthContext'

const Score2Op: React.FC<CalculationProps> = (props) => {
  const [data, setData] = useState<string | undefined>(undefined)
  const [requiredParams, setRequiredParams] = useState<RequiredParamsProps>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { backendRequest } = useAuth()

  useEffect(() => {
    setIsLoading(true)
    const reqParamsObj = {
      age: props.formData.age,
      gender: props.formData.gender,
      smoker: props.formData.smoker,
      risk_region: props.formData.risk_region,
      non_hdl_cholesterol: props.formData.non_hdl_cholesterol,
      systolic_pressure: props.formData.systolic_pressure,
    }
    setRequiredParams(reqParamsObj)

    const allParamsPresent = Object.values(reqParamsObj).every((value) => {
      if (value === null || value === undefined || value === '') {
        return false
      }
      return true
    })

    const getData = async () => {
      if (allParamsPresent) {
        const response = await backendRequest({
          method: 'GET',
          endpoint: `/api/medical-calculations/score2-score2-op?risk_region=c&age=${props.formData.age}&gender=male&is_smoker=${props.formData.smoker}&systolic_pressure=${props.formData.systolic_pressure}&non_hdl_colesterol_mmol_l=${props.formData.non_hdl_cholesterol}&gender=${props.formData.gender}`,
          requiresAuth: true,
        })
        return response
      } else {
        return undefined
      }
    }
    getData().then((response) => {
      if (response && response.status === 200) {
        setData(response.data)
      } else {
        setData(undefined)
      }
      setIsLoading(false)
    })
  }, [props.formData])

  return (
    <CalculationCard
      title='SCORE2 and SCORE2-OP'
      value={data}
      units=''
      requiredParams={requiredParams}
      isLoading={isLoading}
      formData={props.formData}
      setFormData={props.setFormData}
    />
  )
}

export default Score2Op
