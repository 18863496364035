import {
  Visibility,
  VisibilityOff,
  Login,
  Tune,
  Calculate,
  CompareArrows,
  CheckCircleOutline,
  TuneOutlined,
  ExpandMore,
  Error,
  CheckCircle,
  Close,
  Warning,
} from '@mui/icons-material'

export const iconsObj = {
  VISIBILITY: <Visibility fontSize='inherit' />,
  VISIBILITYOFF: <VisibilityOff fontSize='inherit' />,
  ERROR: <Error fontSize='medium' color='error' />,
  SUCCESS: <CheckCircle fontSize='medium' color='success' />,
  WARNING: <Warning fontSize='inherit' color='warning' />,
  LOGIN: <Login fontSize='inherit' />,
  DOSAGE_ADJUSTMENT: <Tune fontSize='medium' />,
  MEDICAL_CALCULATION: <Calculate fontSize='medium' />,
  DRUGS_INTERACTIONS: <CompareArrows fontSize='medium' />,
  SUBMIT: <CheckCircleOutline fontSize='inherit' />,
  MODIFY: <TuneOutlined fontSize='inherit' />,
  EXPAND_MORE: <ExpandMore fontSize='large' />,
  CLOSE: <Close fontSize='inherit' />,
}
