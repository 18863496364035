import React from 'react'
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, RadioGroupProps } from '@mui/material'
import { Controller, Control } from 'react-hook-form'

import { PatientFormData } from './Constants'

type Props = RadioGroupProps & {
  id: string
  label: string
  defaultValue: string
  options: FormControlLebelOptions[]
  control: Control<PatientFormData>
}

type FormControlLebelOptions = {
  id: string
  value: string
  label: string
}

const OptionsInputField: React.FC<Props> = (props) => {
  return (
    <Controller
      key={props.id}
      name={props.id as keyof PatientFormData}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <FormControl component='fieldset'>
          <FormLabel component='legend'>{props.label}</FormLabel>
          <RadioGroup row aria-label={props.label} name={props.id} value={field.value} onChange={(e) => field.onChange(e.target.value)}>
            {props.options.map((option) => (
              <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}

export default OptionsInputField
