import React, { useMemo } from 'react'

import { Grid, Paper, Typography, Box, Chip } from '@mui/material'
import { DataGridProps, GridRenderCellParams } from '@mui/x-data-grid'

import { InteractioSeverities, severityColors } from '../Constants'
import { Table } from '../Table'
import { FoundDrug, NotFoundDrug, InteractionSpc, ActiveSubstance } from '../../types/DrugsInteractions'
import SpcDialog from '../dialogs/SpcDialog'
import ActiveSubstanceCell from '../ActiveSubstanceCell'
import InteractionCell from '../InteractionCell'

type Props = {
  drugsInteractionsSpcs: InteractionSpc[] | []
  foundDrugs: FoundDrug[] | []
  notFoundDrugs: NotFoundDrug[] | []
  isLoading?: boolean
}

type InteractionSeverityCellProps = { severity: InteractioSeverities }

const InteractionSeverityCell: React.FC<InteractionSeverityCellProps> = ({ severity }) => {
  const color = severityColors[severity]
  return <Chip size='small' label={severity} sx={{ backgroundColor: color, color: 'white' }} />
}

type Anamnesis = {
  anamnesis_item: string
  active_substances: ActiveSubstance[]
}

const DrugsInteractions: React.FC<Props> = (props) => {
  const [spcOpen, spcSetOpen] = React.useState<boolean>(false)
  const [interactionChecker, setInteractionChecker] = React.useState<string>('')

  const anamnesis: Anamnesis[] = [
    ...props.notFoundDrugs.map((notFoundDrug) => ({
      anamnesis_item: notFoundDrug,
      active_substances: [],
    })),
    ...props.foundDrugs.map((foundDrug) => ({
      anamnesis_item: foundDrug.anamnesis_item,
      active_substances: foundDrug.active_substances,
    })),
  ]

  const anamnesisColumns = useMemo(
    () => [
      { field: 'anamnesis_item', headerName: "Patient's anamnesis items", editable: false, headerAlign: 'left', flex: 1 },
      {
        field: 'active_substance',
        headerName: 'Active substance',
        editable: false,
        headerAlign: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => <ActiveSubstanceCell activeSubstances={params.row.active_substances} />,
      },
    ],
    []
  )

  const interactionsColumns = useMemo(
    () => [
      {
        field: 'interaction',
        headerName: 'Interaction',
        editable: false,
        sortable: false,
        headerAlign: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <InteractionCell
            firstDrug={params.row.first_drug}
            secondDrug={params.row.second_drug}
            hasFirstDrugSpc={params.row.has_first_drug_spc}
            hasSecondDrugSpc={params.row.has_second_drug_spc}
            setInteractionChecker={setInteractionChecker}
            spcSetOpen={spcSetOpen}
          />
        ),
      },
      {
        field: 'severity',
        headerName: 'Severity',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params: GridRenderCellParams) => <InteractionSeverityCell severity={params.row['severity']} />,
      },
      { field: 'source_text', headerName: 'Description', editable: false, headerAlign: 'left', sortable: false, flex: 1 },
    ],
    []
  )

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent='center' sx={{ minHeight: 'calc(65vh)' }}>
        <Grid item xs={11} sm={11} md={10} lg={5} xl={5} sx={{ minHeight: '100%', display: 'flex', flexdirection: 'column' }}>
          <Paper sx={{ width: '100%', boxShadow: 6, minHeight: '40rem', height: '100%' }}>
            <Grid container sx={{ m: 2 }}>
              <Grid item>
                <Typography sx={{ fontWeight: 700 }} component='div' display='inline'>
                  Patient's medical anamnesis
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', height: '90%' }}>
              <Table
                columns={anamnesisColumns as DataGridProps['columns']}
                rows={anamnesis}
                getRowId={(row) => row['anamnesis_item'] + row['active_substance']}
                noRowsText="Patient's medical anamnesis was not entered"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={11} sm={11} md={10} lg={7} xl={7} sx={{ minWidth: '100px', minHeight: '100%', display: 'flex', flexdirection: 'column' }}>
          <Paper sx={{ width: '100%', boxShadow: 6, minHeight: '40rem', height: '100%' }}>
            <Grid container sx={{ m: 2 }}>
              <Grid item>
                <Typography sx={{ fontWeight: 700 }} component='div' display='inline'>
                  Drugs Interactions
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', height: '90%' }}>
              <Table
                columns={interactionsColumns as DataGridProps['columns']}
                rows={props.drugsInteractionsSpcs}
                getRowId={(row) => row['id']}
                noRowsText='No interactions found'
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {spcOpen && <SpcDialog open={spcOpen} setOpen={spcSetOpen} title={interactionChecker} interactionChecker={interactionChecker} />}
    </React.Fragment>
  )
}

export default DrugsInteractions
