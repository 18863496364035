import React, { createContext, useContext, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import jwt_decode from 'jwt-decode'

type UseFetchProps = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT'
  endpoint: string
  body?: FormData | object | null
  params?: FormData | object | null
  requiresAuth?: boolean
  headers?: Headers
}

type LoginData = {
  access_token: string
}

type DecodedJWTToken = {
  exp: number
}

type AuthContextType = {
  authToken: string | null
  isUserAuthenticated: boolean
  isTokenValid: (authToken?: string | null) => boolean
  backendRequest: (props: UseFetchProps) => Promise<AxiosResponse>
  login: (username: string, password: string) => Promise<string>
  logout: () => void
}

export const AuthContext = createContext<AuthContextType | null>(null)

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext) as AuthContextType
}

type Props = {
  children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false)

  const [loading] = useState<boolean>(false)

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    setAuthToken(token)

    if (token) {
      const isValid = isTokenValid(token)
      if (!isValid) {
        setAuthToken('')
      }
    }
  }, [])

  const backendRequest: (props: UseFetchProps) => Promise<AxiosResponse> = async (props) => {
    const headers: Record<string, string> = { 'Content-Type': 'application/json' }

    if (props.requiresAuth === true) {
      headers['Authorization'] = 'Bearer ' + authToken
      updateTokenSilently()
    }

    const response = await axios({
      url: process.env.REACT_APP_BACKEND_URL + props.endpoint,
      method: props.method,
      data: props.body,
      params: props.params,
      withCredentials: true,
      timeout: 60 * 3 * 1000,
      headers: headers,
    }).then((response) => response)

    return response
  }

  const login: (username: string, password: string) => Promise<string> = async (username, password) => {
    const params = new URLSearchParams()
    params.append('username', username)
    params.append('password', password)

    const response = await axios
      .post<LoginData>(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token`, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          accept: 'application/json',
        },
      })
      .then((response) => response)
      .catch((error) => error)
    if (response.status === 200) {
      setAuthToken(response.data.access_token)
      setIsUserAuthenticated(true)
      localStorage.setItem('authToken', response.data.access_token)
    }
    return response.status
  }

  const logout = () => {
    localStorage.removeItem('authToken')
    setAuthToken(null)
    setIsUserAuthenticated(false)
  }

  const updateTokenSilently = async () => {
    try {
      if (authToken !== null) {
        const headers: Record<string, string> = { 'Content-Type': 'application/json' }
        headers['Authorization'] = 'Bearer ' + authToken

        const decodedToken: DecodedJWTToken = jwt_decode(authToken)

        // Check if the token is about to expire (e.g., within the next 5 minutes)
        const currentTime = Date.now() / 1000
        if (decodedToken.exp - currentTime < 300) {
          const response = await axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/api/auth/update-token`,
            method: 'POST',
            withCredentials: false,
            timeout: 60 * 3 * 1000,
            headers: headers,
          })
            .then((response) => response)
            .catch((error) => error)

          if (response.status === 200) {
            setAuthToken(response.data.access_token)
            setIsUserAuthenticated(true)
            localStorage.setItem('authToken', response.data.access_token)
          } else {
            logout()
          }
        }
      }
    } catch (error) {
      // Handle token decoding or network errors here
      console.error('Error updating token:', error)
    }
  }

  const isTokenValid = (token?: string | null) => {
    token = token || authToken
    try {
      if (token !== null) {
        const decodedToken: DecodedJWTToken = jwt_decode(token)

        // Check if the token has expired
        const currentTime = Date.now() / 1000
        if (decodedToken.exp < currentTime) {
          setIsUserAuthenticated(false)
          return false // Token has expired
        }
        setIsUserAuthenticated(true)
        return true // Token is valid
      }
      setIsUserAuthenticated(false)
      return false
    } catch (error) {
      // If there's an error decoding the token, consider it invalid
      setIsUserAuthenticated(false)
      return false
    }
  }

  const value: AuthContextType = {
    authToken,
    isUserAuthenticated,
    isTokenValid,
    backendRequest,
    login,
    logout,
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
