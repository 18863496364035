import React, { useEffect, useState } from 'react'

import { CalculationProps, RequiredParamsProps, OptionalParamsProps } from '../../types/MedicalCalculations'
import CalculationCard from './CalculationCard'
import { useAuth } from '../../context/AuthContext'

const MetabolicSyndrome: React.FC<CalculationProps> = (props) => {
  const [data, setData] = useState<string | undefined>(undefined)
  const [requiredParams, setRequiredParams] = useState<RequiredParamsProps>({})
  const [optionalParams, setOptionalParams] = useState<OptionalParamsProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { backendRequest } = useAuth()

  useEffect(() => {
    setIsLoading(true)
    const reqParamsObj = {
      gender: props.formData.gender,
      waist_circumference: props.formData.waist_circumference,
      hypertension: props.formData.hypertension,
      hdl_cholesterol: props.formData.hdl_cholesterol,
      glucose_s: props.formData.glucose_s,
      diabetes_history: props.formData.diabetes_history,
    }
    setRequiredParams(reqParamsObj)

    const optParams = [
      {
        labels: ['Triglycerides', 'Hyperlipidemia pharmacologic treatment'],
        params: ['triglycerides', 'hyperlipidemia_pharmacologic_treatment'],
        value: props.formData.hyperlipidemia_pharmacologic_treatment !== '' || props.formData.triglycerides !== '' ? true : false,
      },
    ]
    setOptionalParams(optParams)

    const reqParamsPresent = Object.values(reqParamsObj).every((value) => {
      if (value === null || value === undefined || value === '') {
        return false
      }
      return true
    })

    const optParamsPresent = optParams.every((obj) => {
      if (obj.value === true) {
        return true
      }
      return false
    })

    const getData = async () => {
      if (reqParamsPresent && optParamsPresent) {
        const response = await backendRequest({
          method: 'GET',
          endpoint: '/api/medical-calculations/metabolic-syndrome',
          requiresAuth: true,
          params: {
            waist_circumference: props.formData.waist_circumference,
            hypertension: props.formData.hypertension,
            gender: props.formData.gender,
            hdl_cholesterol: props.formData.hdl_cholesterol,
            glucose_s: props.formData.glucose_s,
            diabetes_history: props.formData.diabetes_history,
            triglycerides: props.formData.triglycerides !== '' ? props.formData.triglycerides : null,
            hyperlipidemia_pharmacologic_treatment:
              props.formData.hyperlipidemia_pharmacologic_treatment !== '' ? props.formData.hyperlipidemia_pharmacologic_treatment : null,
          },
        })
        return response
      } else {
        return undefined
      }
    }
    getData().then((response) => {
      if (response && response.status === 200) {
        setData(response.data)
      } else {
        setData(undefined)
      }
      setIsLoading(false)
    })
  }, [props.formData])

  return (
    <CalculationCard
      title='Metabolic syndrome'
      value={data}
      units=''
      requiredParams={requiredParams}
      optionalParams={optionalParams}
      isLoading={isLoading}
      formData={props.formData}
      setFormData={props.setFormData}
    />
  )
}

export default MetabolicSyndrome
